.loader{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #eee;
    border-top-color: #000;
    margin: auto;
    animation-name: rotate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1s;
}
@keyframes rotate{
    0%{transform: rotate(0deg)}
    25%{transform: rotate(90deg);}
    50%{transform: rotate(180deg);}
    75%{transform: rotate(270deg);}
    100%{transform: rotate(360deg);}
}
