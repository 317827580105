.main{
    align-items: center;
    width: 80%;
}

.main img{
    margin: auto;
    max-height: 60%;
    max-width: 80%;
}

.post_detail{
    font-family: "Play Fair";
    font-size: 25px;
    word-spacing:5px;
    color: rgb(43, 43, 43);
    line-height: 40px;
    font-weight: 400;
}