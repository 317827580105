.aboutCard{
    border: 1px solid #ccc;
    padding: 20px;
    max-width: 80%;
    margin: auto;
    display: flex;
    border-radius: 5%;
    box-shadow: 0px 0px 20px #ccc;
    margin-top: 5%;
}
.aboutDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    letter-spacing: 3px;
}

.aboutImage img{
    width: 100%;
    height: auto;
}


@media screen and (max-width:1060px){
    .aboutCard{
        display: grid;
        grid-template-columns: 1fr;
    }
}