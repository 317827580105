.contactForm{
    width: 60%;
    margin: auto;

}

.form-group{
    display: flex;
    grid-gap: 10px;
    justify-content: center;
}

input, textarea{
    padding: 10px;
    border: 1px solid #ccc;
    width: 100%;
    margin: 10px;
    font-family: 'Courier New', Courier, monospace;
}
input:focus{
    border: 1px solid black;
    border-radius: 0px;
    
}