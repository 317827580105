.main {
  margin: auto;
  position: relative;
  width: 80%;
}


.main .text p{
  color: rgb(104, 108, 110);
  font-weight: 100;
}

h1 {
  font-family: "Roboto Condensed", sans-serif;
  color: rgb(22, 20, 20);
}
.main .text {
  margin: auto;
  margin-top: 10%;
  font-size: 35px;
}

.exploreBtn {
  border: 1px solid rgb(119, 115, 115);
  color: black;
  background: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  width: 10rem;
  font-family: "Roboto Condensed", sans-serif;
}

.exploreBtn:hover {
  background-color: rgb(3, 3, 3);
  color: white;
  transition: 0.4s;
}
