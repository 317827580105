.paginate_button{
    margin: 20px;
}

.paginate{
    margin: 5px 10px;
    width: 80px;
    
}

.pagenum{
    border: none;
    background-color: #fff;
    font-family: "Roboto Condensed";
    font-size: 20px;
    cursor: pointer;
    margin: 5px 10px;
}
.pagenum:hover{
    text-decoration: underline;
}

.disabled{
    color: #ccc;
    border-color: #ccc;
}

.disabled:hover{
    cursor: default;
    background-color: #fff;
    color: #ccc;

}