.card{
  border: 1px solid #ccc;
  padding: 20px;
  height: auto;
  width: 17rem;
  box-shadow: 0px 5px 10px #ccc;
  transition: transform;
  transition-duration: 0.3s;
  margin-bottom: 20px;
  margin: auto;
  margin-top: 20px;
}

.card .image img {
  width: 250px;
  border-radius: 50%;
  height: 250px;
}

.card .content .title h1{
  font-family: "PT Sans", sans-serif;
  cursor: pointer;
}
.card .content .description p{
  color: rgb(145, 139, 139);
  font-style: italic;
  font-family: Roboto;
}

.card:hover{
  box-shadow: 0px 0px 20px #ccc;
  transform: translate3d(0,-4px,0);
  
}