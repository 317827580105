.posts {
  margin:auto;
  display: grid;
  width: 80%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.top_items{
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.blogSearch{
  width: 50%;
}

@media screen and (max-width:1060px){
  .posts{
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width:768px){
  .posts{
    grid-template-columns: 1fr;
  }
}
