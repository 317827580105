.comment_body{
    border: 1px solid #ccc;
    margin: 20px;

}

.comment_box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    
}

.comment_box img{
    height: 40px;

}

.comment_box .top{
    display: flex;
    grid-gap: 10px;
}

.comment_box p{
    margin-left: 5%;
    margin-top: -5px;
}