ul {
  padding: 10px;
  display: flex;
  grid-gap: 40px;
  width: 20%;
  font-family: "Roboto Condensed", sans-serif;
  color: rgb(119, 115, 115);
  font-size: 15px;
  margin-left: auto;
}

li {
  list-style: none;
  margin-top: 5px;
}

li:hover {
  color: black;
  cursor: pointer;
}

.navBtn {
  border: 1px solid rgb(119, 115, 115);
  color: black;
  background: none;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
}
a {
  color: rgb(119, 115, 115);
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  ul {
   margin: auto;
   margin-top: 20px;
   
  }

}
